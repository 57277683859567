/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import mobileNavigation from './modules/mobile-navigation'
import initToggleSubMenu from './modules/toggle-sub-menu'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import debug from './modules/debug'
//import Swiper from 'swiper'

/**
 * Ab geht die Reise
 */
domready(() => {
  initToggleSubMenu()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })
  // eslint-disable-next-line
  new Swiper('#slider-header', {
    effect: 'fade',
    slidesPerView: 1,
    autoplay: 5000,
    speed: 3000,
    loop: true
  })
  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 5000,
    speed: 2000,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
// eslint-disable-next-line
  new Swiper('.leistungen-slider', {
    pagination: {
      el: '.leistungen-slider-pagination',
      type: 'bullets',
      clickable: true
    },
    slidesPerView: 1,
    // slidesPerGroup: 2,
    // dynamicBullets: true,
    spaceBetween: 0,
    autoplay: 5000,
    speed: 2000,
    loop: false,
    grid: {
      rows: 1, // your amount of slides
      fill: "row",
    },
    breakpoints: {
      // when window width is <= 320px
      768: {
        slidesPerView: 1,
        grid: {
          rows: 1, // your amount of slides
          fill: "row",
        },
      },
      1024: {
        slidesPerView: 2,
        grid: {
          rows: 2, // your amount of slides
          fill: "row",
        },
      }
    }
  })

  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line
  debug()
  let circles = document.querySelectorAll('.circle-border')
  for (let i = 0; i < circles.length; i++) {
    circles[i].style.transform = 'translate(-50%, -50%) rotate(' + Math.floor(Math.random() * 360) + 'deg)'
  }
})
